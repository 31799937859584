var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "bg-gray-100 text-gray-900 text-base" }, [
      _c(
        "form",
        {
          staticClass:
            "flex flex-wrap items-center border border-gray-500 shadow rounded",
          attrs: { title: "Search", role: "search", action: "/" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.findCandidates({
                search: _vm.search,
                searchType: _vm.searchType
              })
            }
          }
        },
        [
          _c(
            "label",
            { staticClass: "sr-only", attrs: { for: "addressInput" } },
            [_vm._v("Address")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search"
              }
            ],
            staticClass:
              "flex-1 appearance-none placeholder-gray-600 p-2 rounded bg-transparent focus:outline-none focus:shadow-outline",
            attrs: {
              id: "addressInput",
              name: "addressInput",
              type: "search",
              role: "searchbox",
              placeholder: "Search...",
              required: "required",
              "aria-controls": "address-suggest-results"
            },
            domProps: { value: _vm.search },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "relative flex items-center" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchType,
                    expression: "searchType"
                  }
                ],
                staticClass:
                  "appearance-none h-full pl-2 pr-5 py-2 border-transparent rounded bg-transparent focus:outline-none focus:shadow-outline",
                attrs: { "aria-label": "Search type" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.searchType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "address" } }, [
                  _vm._v("Address")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "taxLot" } }, [
                  _vm._v("Tax lot")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "pointer-events-none absolute inset-y-0 right-0 flex items-center"
              },
              [
                _c("i", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.feather.icons["chevron-down"].toSvg({
                        class: "w-5 h-5"
                      })
                    )
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "p-2 rounded focus:outline-none focus:shadow-outline",
              attrs: { "aria-label": "Search" }
            },
            [_c("Search")],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "list-none m-0 p-0 -mt-1 pt-1",
          attrs: { id: "address-suggest-results", role: "listbox" }
        },
        _vm._l(_vm.candidates, function(candidate, index) {
          return _c(
            "li",
            {
              key: candidate.id,
              staticClass:
                "p-2 w-full border-b border-r hover:bg-blue-100 cursor-pointer",
              attrs: {
                id: "address-suggest-result-" + index,
                role: "option",
                tabindex: "0"
              },
              on: {
                click: function($event) {
                  return _vm.selectCandidate(candidate)
                },
                keyup: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.selectCandidate(candidate)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    return _vm.selectCandidate(candidate)
                  }
                ]
              }
            },
            [
              candidate.type
                ? _c(
                    "span",
                    {
                      staticClass:
                        "mx-2 px-2 rounded-full bg-blue-500 text-white text-xs font-bold uppercase"
                    },
                    [_vm._v(_vm._s(candidate.type))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mx-2 text-sm" }, [
                _vm._v(_vm._s(candidate.name))
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-1 items-center mx-2 text-xs font-bold uppercase"
                },
                [
                  candidate.city
                    ? _c("span", {}, [_vm._v(_vm._s(candidate.city) + ",")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", {}, [_vm._v(_vm._s(candidate.state))])
                ]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }