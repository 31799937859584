var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "h-5 w-5",
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", { attrs: { cx: "11", cy: "11", r: "6" } }),
      _vm._v(" "),
      _c("line", { attrs: { x1: "21", y1: "21", x2: "16.05", y2: "16.05" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }