var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "p-2 border border-gray-500 rounded bg-gray-100 flex items-center justify-between text-gray-900"
    },
    [
      _c(
        "button",
        {
          staticClass:
            "py-2 px-3 rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline",
          class: {
            invisible: _vm.value == 0
          },
          attrs: {
            title: "Go to previous page",
            rel: "prev",
            disabled: _vm.value == 0
          },
          on: {
            click: function($event) {
              return _vm.$emit("prev")
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.$emit("prev")
            }
          }
        },
        [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Previous page")]),
          _vm._v(" "),
          _c("span", { staticClass: "flex items-center" }, [
            _c("i", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.feather.icons["chevron-left"].toSvg({ class: "w-5 h-5" })
                )
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "mx-2" }, [
        _vm._v(_vm._s(_vm.value + 1) + " of " + _vm._s(_vm.list.length))
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "py-2 px-3 rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline",
          class: {
            invisible: _vm.list.length == _vm.value + 1
          },
          attrs: {
            title: "Go to next page",
            rel: "prev",
            disabled: _vm.list.length == _vm.value + 1
          },
          on: {
            click: function($event) {
              return _vm.$emit("next")
            },
            "keyup-": function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.$emit("next")
            }
          }
        },
        [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Next page")]),
          _vm._v(" "),
          _c("span", { staticClass: "flex items-center" }, [
            _c("i", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.feather.icons["chevron-right"].toSvg({ class: "w-5 h-5" })
                )
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }