var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass:
        "flex flex-col-reverse sm:flex-row md:flex-col-reverse lg:flex-row"
    },
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Street listings")]),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "w-full sm:w-1/3 md:w-full lg:w-1/3 xl:w-1/4 h-full sm:h-screen md:h-full lg:h-(screen-16) overflow-y-auto border-t sm:border-t-0 md:border-t lg:border-t-0 sm:border-r md:border-r-0 lg:border-r border-black"
        },
        [
          !_vm.$route.params.id
            ? _c(
                "section",
                { staticClass: "m-2" },
                [
                  _c("address-suggest", {
                    on: { "candidate-select": _vm.goToAddress }
                  }),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      staticClass:
                        "my-2 border border-gray-500 rounded shadow bg-gray-100 text-gray-900",
                      attrs: { id: "filters" }
                    },
                    [
                      _c(
                        "header",
                        {
                          staticClass: "border-gray-500",
                          class: {
                            "border-b": _vm.showFilters
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "p-2 w-full flex items-center justify-between focus:outline-none focus:shadow-outline",
                              on: {
                                click: function($event) {
                                  _vm.showFilters = !_vm.showFilters
                                }
                              }
                            },
                            [
                              _c("h2", { staticClass: "flex items-center" }, [
                                _c("i", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.feather.icons["settings"].toSvg({
                                        class: "w-5 h-5"
                                      })
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "px-2" }, [
                                  _vm._v("Settings")
                                ])
                              ]),
                              _vm._v(" "),
                              !_vm.showFilters
                                ? _c("i", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.feather.icons[
                                          "chevron-down"
                                        ].toSvg({ class: "w-5 h-5" })
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showFilters
                                ? _c("i", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.feather.icons["chevron-up"].toSvg({
                                          class: "w-5 h-5"
                                        })
                                      )
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "main",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showFilters,
                              expression: "showFilters"
                            }
                          ],
                          staticClass: "p-2",
                          attrs: { "aria-expanded": _vm.showFilters }
                        },
                        _vm._l(_vm.controllableModelGroups, function(
                          group,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("classification", {
                                attrs: {
                                  group: group,
                                  dataset: _vm.models.reduce(function(
                                    prev,
                                    curr
                                  ) {
                                    if (curr.group === group) {
                                      prev.push(curr)
                                    }
                                    return prev
                                  },
                                  []),
                                  total: _vm.streets.length
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "section",
                    { staticClass: "my-2" },
                    [
                      _vm.streets.length > 0 && _vm.enabledModels.size == 0
                        ? _c("message", {
                            attrs: {
                              item: {
                                type: "warning",
                                text:
                                  "Enable one or more classifications in the settings to display streets..."
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.filteredStreets.length > 0
                    ? _c("div", [
                        _c(
                          "ul",
                          { staticClass: "list-none" },
                          _vm._l(_vm.filteredStreets, function(street) {
                            return _c(
                              "li",
                              { key: street.uuid, staticClass: "my-2" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "flex-shrink flex flex-col h-full px-2 py-3 shadow border rounded bg-white hover:bg-blue-100 focus:bg-blue-100",
                                    attrs: { to: street.id, append: "" },
                                    nativeOn: {
                                      mouseover: function($event) {
                                        return _vm.highlightStreet({
                                          street: street,
                                          move: false
                                        })
                                      },
                                      focus: function($event) {
                                        return _vm.highlightStreet({
                                          street: street,
                                          move: false
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          street.name.trim() ||
                                            "Unnamed segment"
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    street.block
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-base text-gray-700"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(street.block) + " block"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-row flex-wrap -mx-1 text-sm text-gray-600"
                                      },
                                      _vm._l(
                                        _vm.filteredClassifications(
                                          street.classifications
                                        ),
                                        function(c) {
                                          return _c(
                                            "span",
                                            {
                                              key: c.group + "-" + c.value,
                                              staticClass:
                                                "flex flex-row flex-wrap items-center mx-1"
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "h-2 w-2 p-1 mr-1 border border-gray-900",
                                                style: {
                                                  "background-color": _vm
                                                    .classificationColor(
                                                      c.group,
                                                      c.value
                                                    )
                                                    .formatRgb()
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.classificationLabel(
                                                      c.group,
                                                      c.value
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.$route.params.id && _vm.selectedStreets
              ? _c(
                  "div",
                  { staticClass: "m-2" },
                  [
                    _c(
                      "header",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "border-current border-b-2 transition ease-in-out duration-150 hover:text-blue-600 focus:text-blue-600",
                            attrs: { to: "/streets" }
                          },
                          [_vm._v("Back to results")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.selectedStreets && _vm.selectedStreets.length > 1
                      ? _c("pager", {
                          staticClass: "my-3",
                          attrs: {
                            list: _vm.selectedStreets.reduce(function(
                              prev,
                              curr
                            ) {
                              prev.push(curr.id)
                              return prev
                            },
                            [])
                          },
                          on: {
                            next: function($event) {
                              return _vm.handleSelectionChange(
                                _vm.selectionIndex + 1
                              )
                            },
                            prev: function($event) {
                              return _vm.handleSelectionChange(
                                _vm.selectionIndex - 1
                              )
                            }
                          },
                          model: {
                            value: _vm.selectionIndex,
                            callback: function($$v) {
                              _vm.selectionIndex = $$v
                            },
                            expression: "selectionIndex"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedStreet
                      ? _c("street-component", {
                          attrs: { street: _vm.selectedStreet }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "w-full sm:w-2/3 md:w-full lg:w-2/3 xl:w-3/4 h-screen-50 sm:h-screen md:h-screen-50 lg:h-(screen-16) overflow-y-auto"
        },
        [
          _c("app-map", {
            attrs: {
              layers: _vm.models.reduce(function(prev, curr) {
                if (curr.layer) {
                  prev.push(curr.layer)
                }
                return prev
              }, [])
            },
            on: {
              click: _vm.handleClick,
              "extent-change": _vm.handleExtentChange
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }