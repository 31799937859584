








































import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';

import feather from 'feather-icons';
import debounce from 'lodash-es/debounce';

import { whenTrue } from 'esri/core/watchUtils';
import { Extent } from 'esri/geometry';
import MapView from 'esri/views/MapView';
import Legend from 'esri/widgets/Legend';

import { MapState } from '../store/map/types';

export default Vue.extend({
  name: 'Map',

  props: {
    legend: {
      type: Boolean,
      default: true
    },
    layers: {
      type: Array
    }
  },
  data: function() {
    return {
      clickPoint: null,
      displayExtent: {},
      feather,
      selectedGraphic: null,
      showSettings: false,
      showLegend: false
    };
  },
  computed: {
    ...mapState('map', {
      map: (state: MapState) => state.map,
      view: (state: MapState) => state.view,
      extent: (state: MapState) => state.extent,
      zoom: (state: MapState) => state.zoom.current
    })
  },
  watch: {
    layers(newLayers) {
      this.setLayers(newLayers);
    }
  },
  methods: {
    ...mapActions('map', ['setExtent', 'setZoom', 'setLayerVisibility']),
    ...mapMutations('map', ['setView', 'setLayers']),
    initHandlers(view: MapView) {
      view.on('click', (event: __esri.MapViewClickEvent) => {
        this.$emit('click', event);
      });

      view.on('pointer-move', (event: __esri.MapViewPointerMoveEvent) => {
        view.hitTest(event).then((response: __esri.HitTestResult) => {
          this.$emit('pointer-hit', response.results);
        });
      });
    },
    toggleLayerVisibility(id: string, value: boolean) {
      this.setLayerVisibility({ layerId: id, visible: value });
    },
    incrementZoom() {
      this.setZoom({ value: this.zoom + 1, move: true });
    },
    decrementZoom() {
      this.setZoom({ value: this.zoom - 1, move: true });
    }
  },
  mounted: function() {
    const view = new MapView({
      container: this.$refs.map as HTMLDivElement,
      map: this.map,
      extent: this.extent
    });

    view.ui.remove('zoom');

    view.ui.add(this.$refs['top-left'] as HTMLDivElement, {
      position: 'top-left',
      index: 2
    });
    view.ui.add(this.$refs['top-right'] as HTMLDivElement, {
      position: 'top-right',
      index: 2
    });
    view.ui.add(this.$refs['bottom-left'] as HTMLDivElement, {
      position: 'bottom-left',
      index: 2
    });
    view.ui.add(this.$refs['bottom-right'] as HTMLDivElement, {
      position: 'bottom-right',
      index: 2
    });
    view.ui.add(this.$refs['manual'] as HTMLDivElement, {
      position: 'manual',
      index: 2
    });

    if (this.legend) {
      new Legend({
        view,
        container: this.$refs['legend'] as HTMLDivElement
      });
    }

    view.watch(
      'extent',
      debounce((newValue: Extent) => {
        this.setExtent(newValue);
        this.$emit('extent-change', newValue);
      }, 500)
    );

    whenTrue(view, 'stationary', () => {
      if (view.zoom) {
        this.setZoom({ value: view.zoom, move: false });
      }
    });

    this.setView(view);

    this.initHandlers(view);

    this.setLayers(this.layers);
  }
});
