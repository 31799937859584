var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full" }, [
    _c("section", { staticClass: "h-full" }, [
      _c("div", { ref: "map", staticClass: "relative h-full w-full" })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "manual", staticClass: "h-full w-full" },
      [_vm._t("manual")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { ref: "top-left" },
      [
        _vm._t("top-left", [
          _c("div", { staticClass: "flex flex-col shadow-lg" }, [
            _c(
              "button",
              {
                staticClass:
                  "p-2 bg-white border-2 border-b rounded-t border-fog-900 focus:outline-none focus:shadow-outline",
                attrs: { title: "Zoom in" },
                on: { click: _vm.incrementZoom }
              },
              [
                _c("i", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.feather.icons["plus"].toSvg({ class: "w-5 h-5" })
                    )
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "p-2 bg-white border-2 border-t rounded-b border-fog-900 focus:outline-none focus:shadow-outline",
                attrs: { title: "Zoom out" },
                on: { click: _vm.decrementZoom }
              },
              [
                _c("i", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.feather.icons["minus"].toSvg({ class: "w-5 h-5" })
                    )
                  }
                })
              ]
            )
          ])
        ])
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { ref: "top-right" }, [_vm._t("top-right")], 2),
    _vm._v(" "),
    _c("div", { ref: "bottom-left" }, [_vm._t("bottom-left")], 2),
    _vm._v(" "),
    _c("div", { ref: "bottom-right" }, [_vm._t("bottom-right")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }