



















































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

import { AreaPlan } from '../store/area_plans/types';
import { MasterStreetPlan } from '../store/master_street_plans/types';

export default Vue.extend({
  name: 'Street',
  props: {
    street: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('streets', ['classificationLabel', 'classificationColor']),
    plans() {
      const plans: Array<{ id: string; name: string; type: string }> = [
        ...this.street.masterStreetPlans.map((plan: MasterStreetPlan) => {
          const { name, id } = plan;
          return {
            id,
            name,
            type: 'master'
          };
        }),
        ...this.street.areaPlans.map((plan: AreaPlan) => {
          const { name, id } = plan;
          return {
            id,
            name,
            type: 'area'
          };
        })
      ].sort((a, b) => {
        const nameA = a.name?.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name?.toUpperCase(); // ignore upper and lowercase

        if (!nameA || !nameB) {
          return Number.MAX_SAFE_INTEGER;
        }
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      return plans;
    }
  },
  methods: {
    classificationKeys: function() {
      return this.street && this.street.classifications ? Object.keys(this.street.classifications) : [];
    }
  }
});
