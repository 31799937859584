





























































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState, mapActions } from 'vuex';

import feather from 'feather-icons';

import { ViewModel, StreetState } from '@/store/streets/types';

// Define the props by using Vue's canonical way.
const ClassificationProps = Vue.extend({
  props: {
    group: {
      type: String,
      required: true
    },
    dataset: {
      type: Array as () => Array<ViewModel>,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  }
});

@Component({
  name: 'Classification',
  data() {
    return {
      show: false,
      feather
    };
  },
  computed: {
    ...mapState('streets', {
      streets: (state: StreetState) => state.list
    })
  },
  methods: {
    ...mapActions('map', ['setLayerVisibility'])
  }
})
export default class Classification extends ClassificationProps {
  setLayerVisibility!: (payload: { layerId: string; visible: boolean }) => void;

  get enabled() {
    return this.dataset.reduce((prev, curr) => {
      return prev || curr.enabled;
    }, false);
  }

  setGroupVisibility(value: boolean) {
    for (const entry of this.dataset) {
      this.setVisibility(entry, value);
    }
  }

  setVisibility(model: ViewModel, value: boolean) {
    model.enabled = value;
    if (model.layer) {
      model.layer.visible = value;
      this.setLayerVisibility({ layerId: model.layer?.id, visible: value });
    }
  }
}
