






































import Vue from 'vue';

import feather from 'feather-icons';

export default Vue.extend({
  name: 'Pager',
  props: {
    value: {
      type: Number
    },
    list: {
      type: Array
    }
  },
  data() {
    return { feather };
  }
});
