var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("main", { staticClass: "my-2 flex flex-col" }, [
        _c("section", { staticClass: "flex items-center justify-between" }, [
          _c(
            "label",
            { staticClass: "flex items-center", attrs: { for: _vm.group } },
            [
              _c("input", {
                attrs: { type: "checkbox", id: _vm.group, title: _vm.group },
                domProps: { checked: _vm.enabled },
                on: {
                  change: function($event) {
                    return _vm.setGroupVisibility($event.target.checked)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "mx-2 flex" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.group.charAt(0).toUpperCase() + _vm.group.slice(1)
                    ) +
                    "\n          classifications\n        "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "flex-1 flex flex-col items-end",
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              !_vm.show
                ? _c("i", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.feather.icons["chevron-down"].toSvg({
                          class: "w-5 h-5"
                        })
                      )
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.show
                ? _c("i", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.feather.icons["chevron-up"].toSvg({
                          class: "w-5 h-5"
                        })
                      )
                    }
                  })
                : _vm._e()
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "pop" } }, [
        _vm.show
          ? _c(
              "footer",
              {
                staticClass:
                  "my-2 p-2 bg-fog-100 text-fog-900 border border-fog-900 rounded"
              },
              _vm._l(_vm.dataset, function(entry) {
                return _c(
                  "label",
                  {
                    key: entry.value,
                    staticClass: "flex items-center",
                    attrs: { for: entry.key }
                  },
                  [
                    _c("input", {
                      attrs: { type: "checkbox", id: entry.key },
                      domProps: { checked: entry.enabled },
                      on: {
                        change: function($event) {
                          return _vm.setVisibility(entry, $event.target.checked)
                        }
                      }
                    }),
                    _vm._v(" "),
                    entry.symbol.type == "color"
                      ? _c("div", {
                          staticClass:
                            "h-4 w-4 px-2 mx-2 border border-gray-900",
                          style: {
                            "background-color": entry.symbol.value.formatRgb()
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    entry.symbol.type == "image"
                      ? _c(
                          "div",
                          { staticClass: "mx-2 border border-gray-900" },
                          [
                            _c("img", {
                              staticClass: "h-4 w-4",
                              attrs: {
                                src:
                                  "data:" +
                                  entry.symbol.mime +
                                  ";base64," +
                                  entry.symbol.value
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "px-2" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "border-current border-b-2 hover:text-blue-600 focus:text-blue-600",
                            attrs: {
                              to: {
                                name: "text",
                                hash:
                                  "#" +
                                  entry.label
                                    .toLowerCase()
                                    .split(" ")
                                    .join("-")
                              }
                            }
                          },
                          [_vm._v(_vm._s(entry.label))]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }