var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "my-3" },
    [
      _c("h1", { staticClass: "mb-3 text-3xl lg:text-4xl" }, [
        _vm._v(_vm._s(_vm.street.name.trim() || "Unnamed segment"))
      ]),
      _vm._v(" "),
      _vm.street.block
        ? _c("p", { staticClass: "my-3 text-2xl text-gray-700" }, [
            _vm._v(_vm._s(_vm.street.block) + " block")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("section", [
        _c(
          "dl",
          [
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3"
              },
              [
                _c("dt", { staticClass: "text-gray-700" }, [
                  _vm._v("Transportation plan ID")
                ]),
                _vm._v(" "),
                _c("dd", [_vm._v(_vm._s(_vm.street.id))])
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.classificationKeys(), function(classification, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3"
                },
                [
                  _c("dt", { staticClass: "text-gray-700" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          classification.charAt(0).toUpperCase() +
                            classification.slice(1)
                        ) +
                        " classification\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "dd",
                    [
                      classification != "greenscape" &&
                      _vm.classificationLabel(
                        classification,
                        _vm.street.classifications[classification]
                      ) != "N/A"
                        ? _c(
                            "router-link",
                            {
                              staticClass:
                                "border-current border-b-2 transition ease-in-out duration-150 hover:text-blue-600 focus:text-blue-600",
                              attrs: {
                                to: {
                                  name: "text",
                                  hash:
                                    "#" +
                                    _vm
                                      .classificationLabel(
                                        classification,
                                        _vm.street.classifications[
                                          classification
                                        ]
                                      )
                                      .toLowerCase()
                                      .split(" ")
                                      .join("-")
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.classificationLabel(
                                    classification,
                                    _vm.street.classifications[classification]
                                  )
                                )
                              )
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.classificationLabel(
                                  classification,
                                  _vm.street.classifications[classification]
                                )
                              )
                            )
                          ])
                    ],
                    1
                  )
                ]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.street.projects && _vm.street.projects.length > 0
          ? _c("section", [
              _c("h2", { staticClass: "mb-3 text-2xl lg:text-3xl" }, [
                _vm._v("Projects near this street")
              ]),
              _vm._v(" "),
              _c(
                "ul",
                {},
                _vm._l(_vm.street.projects, function(project) {
                  return _c(
                    "li",
                    { key: project.id },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "flex flex-col my-2 px-2 py-3 shadow border rounded bg-white hover:bg-blue-100 focus:bg-blue-100",
                          attrs: { to: "/projects/" + project.id }
                        },
                        [
                          _c("h3", [_vm._v(_vm._s(project.name))]),
                          _vm._v(" "),
                          _c("p", { staticClass: "my-2 text-sm" }, [
                            _vm._v(_vm._s(project.description))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-row flex-wrap -mx-2 text-sm text-gray-600"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "mx-2 flex flex-row items-center"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-3 h-3 mr-1",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none",
                                        stroke: "currentColor",
                                        "stroke-width": "2",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round"
                                      }
                                    },
                                    [
                                      _c("line", {
                                        attrs: {
                                          x1: "12",
                                          y1: "1",
                                          x2: "12",
                                          y2: "23"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        project.estimatedCost.toLocaleString()
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "mx-2 flex flex-row items-center"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-3 h-3 mr-1",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none",
                                        stroke: "currentColor",
                                        "stroke-width": "2",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round"
                                      }
                                    },
                                    [
                                      _c("rect", {
                                        attrs: {
                                          x: "3",
                                          y: "4",
                                          width: "18",
                                          height: "18",
                                          rx: "2",
                                          ry: "2"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("line", {
                                        attrs: {
                                          x1: "16",
                                          y1: "2",
                                          x2: "16",
                                          y2: "6"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("line", {
                                        attrs: {
                                          x1: "8",
                                          y1: "2",
                                          x2: "8",
                                          y2: "6"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("line", {
                                        attrs: {
                                          x1: "3",
                                          y1: "10",
                                          x2: "21",
                                          y2: "10"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(project.estimatedTimeframe))
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.plans && _vm.plans.length > 0
        ? _c("section", [
            _c("h2", { staticClass: "mb-3 text-2xl lg:text-3xl" }, [
              _vm._v("Plans near this street")
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {},
              _vm._l(_vm.plans, function(plan) {
                return _c(
                  "li",
                  { key: plan.id },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "block my-2 px-2 py-3 shadow border rounded bg-white hover:bg-blue-100 focus:bg-blue-100",
                        attrs: {
                          to:
                            "/" +
                            (plan.type === "master"
                              ? "master-street-plans"
                              : "area-plans") +
                            "/" +
                            plan.id
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "px-2 py-1 mb-2 bg-fog-300 text-fog-900 text-sm rounded-md shadow inline-block"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                plan.type === "master"
                                  ? "Master street"
                                  : "Area"
                              ) + " plan"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("h3", [_vm._v(_vm._s(plan.name))]),
                        _vm._v(" "),
                        _c("p", { staticClass: "my-2 text-sm" }, [
                          _vm._v(_vm._s(plan.description))
                        ])
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }